const theme = {
  breakpoints: ["576px", "768px", "1024px", "1280px"],
  color: {
    primary400: "#2F691B",
    primary300: "#467E16",
    primary200: "#8DC640",
    secondary200: "#F7931E",
    blue400: "rgba(0,161,188,1)",
    blue300: "rgba(0,207,242,1)",
    heading: "#1D1D1D",
    text: "#7A7A7A",
  },
}
export default theme
