import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import theme from "./static/assets/theme";

const wrapWithTheme = props => {
  const { element } = props;

  return (
    <PageProvider>
        <ThemeProvider theme={theme}>
          <Helmet
            htmlAttributes={{
              lang: "en",
            }}
          >
          </Helmet>
          <div style={{ overflow: "hidden" }}>{element}</div>
        </ThemeProvider>
    </PageProvider>
  )
};

export const PageContext = React.createContext(null);

const PageProvider = props => {
  const pageRef = useRef(null)
  return (
    <PageContext.Provider value={pageRef}>
      <div ref={pageRef}>
        {props.children}
      </div>
    </PageContext.Provider>
  )
};

export default wrapWithTheme;